import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ArrowSvgIcon from '../../images/icons/arrow.inline.svg';
import useZoomEffect from '../../utils/useZoomEffect';
import { WHITE_ON_BLACK_PSEUDO_CLASS } from '../Colors';

const ScoopLanding: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('landing');
  const [scrolledElement, animatedElement] = useZoomEffect<HTMLDivElement, HTMLHeadingElement>();
  const altTitle = t('scoopLanding.alt-image');
  const buttonUrl = t('scoopLanding.button-url');
  let propButtonUrl;
  if (buttonUrl.startsWith('http')) {
    propButtonUrl = { target: '_blank', rel: 'noopener noreferrer' };
  }

  return (
    <section id="scoop-landing">
      <div className="overflow-hidden bg-black text-white h-md:lg:h-screen h-md:lg:snap-start">
        <div
          ref={scrolledElement}
          className="container flex h-full justify-center px-4 py-12 sm:px-8 h-md:lg:py-0"
        >
          <div className="container flex h-full flex-col justify-center px-4 py-12 sm:px-8 h-md:lg:py-0">
            <h3
              ref={animatedElement}
              className="origin-[50%_75%_0px] text-left text-4xl font-semibold"
            >
              {t('scoopLanding.title1')}
            </h3>
            {t('scoopLanding.title2') !== '' ? (
              <h4 className="text-left text-2xl">{t('scoopLanding.title2')}</h4>
            ) : null}
            <p className="mb-8">{t('scoopLanding.description1')}</p>
            {t('scoopLanding.description2') !== '' ? (
              <p className="mb-8">{t('scoopLanding.description2')}</p>
            ) : null}
            {t('scoopLanding.description3') !== '' ? (
              <p className="mb-8">{t('scoopLanding.description3')}</p>
            ) : null}
            <a
              href={buttonUrl}
              {...propButtonUrl}
              title={t('scoopLanding.button')}
              className={`inline-block w-fit whitespace-nowrap rounded-1 border-1 border-current px-4 py-3 font-semibold ${WHITE_ON_BLACK_PSEUDO_CLASS}`}
            >
              <span className="pl-5 pr-6">{t('scoopLanding.button')}</span>
              <ArrowSvgIcon className="inline-block" />
            </a>
            <div className="mt-8 md:hidden">
              <StaticImage
                src="../../images/scoopLanding.png"
                alt={altTitle}
                title={altTitle}
                width={578}
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="container hidden h-full flex-col justify-center self-center px-4 py-12 sm:px-8 md:flex h-md:lg:py-0">
            <StaticImage
              src="../../images/scoopLanding.png"
              alt={altTitle}
              title={altTitle}
              width={578}
              placeholder="blurred"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScoopLanding;
