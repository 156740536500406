import { StaticImage } from 'gatsby-plugin-image';
// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SvgLogo from '../../images/logo.inline.svg';
import * as Colors from '../Colors';
import Contact from '../Contact';
import Hiring from '../Hiring';

type FooterProps = {
  pageTitle: string;
  hiringClassName?: string;
  logoClassName?: string;
  scrollSnap?: boolean;
  theme?: Colors.Theme;
};

const Footer: React.VoidFunctionComponent<FooterProps> = ({
  pageTitle,
  hiringClassName = Colors.BLACK_ON_WHITE,
  logoClassName = Colors.WHITE_ON_BLACK,
  scrollSnap = false,
  theme = {
    class: Colors.BLACK_ON_WHITE,
    pseudoClass: Colors.BLACK_ON_WHITE_PSEUDO_CLASS,
  },
}) => {
  const { t } = useTranslation('hiring');

  return (
    <section id="footer" className="relative z-20">
      <div className={`lg:h-screen ${scrollSnap ? 'lg:snap-start' : ''}`}>
        <div className="flex h-full flex-col justify-center">
          <Hiring className={`${hiringClassName} h-full flex-1/2 lg:max-h-1/2`} theme={theme} />
          <div className="flex h-full flex-1/2 flex-col justify-center lg:max-h-1/2 lg:flex-row">
            <div className={`${logoClassName} relative flex-1 lg:min-h-full`}>
              <StaticImage
                src="../../images/join-us-work.jpg"
                className="h-full w-full"
                title={t('join-us')}
                alt={t('join-us')}
                placeholder="blurred"
              />
              <SvgLogo className="absolute bottom-4 left-4 h-12 w-12 rounded-full bg-white text-reacteev-blue sm:bottom-8 sm:left-8 sm:h-16 sm:w-16 md:bottom-12 md:left-12 md:h-auto md:w-auto" />
            </div>
            <div className="z-10 flex flex-1 flex-col justify-center bg-reacteev-blue text-white">
              <Contact
                pageTitle={pageTitle}
                containerClassName="h-80 sm:h-96 lg:h-full"
                contactInfoClassName="flex flex-col justify-center px-2 xs:px-4 xl:px-8"
                contactFormClassName="flex flex-col justify-center"
                titleClassName="text-3xl leading-snug sm:text-4xl font-bold mt-0"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
